.App {
  text-align: center;
}

.logo {
  position: relative;
  height: 15vh;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 25px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  animation: logoA 500ms linear;
  opacity: 1;
  -webkit-animation-fill-mode:forwards;
  animation-fill-mode:forwards;
}

.logo-white {
  opacity: 0;
  animation: opacityOnToOff 2s linear;
}


.icon-tech
{
  height: 35px;
  width: 35px;
  margin-left: 10px;
}

.icon
{
  position: relative;
  fill: #61dafb;
  height: 35px;
  width: 35px;
  left : 0;
}

.icon-fade-in
{
  animation: iconFadeInA 1s linear;
}

.icon-wrapper-pointer
{
  cursor: pointer;
}

.icon-wrapper-none
{
  cursor: none;
}

.icon-wrapper-shake
{
  animation: shake 750ms linear;
  cursor: none;

}

.cursor {
    width: 9px;
    height: 18px;
    background: white;
    animation: opacityOnToOff 650ms linear;
    animation-iteration-count:infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -1px) rotate(-1deg); }
  20% { transform: translate(-1px, 0px) rotate(1deg); }
  30% { transform: translate(1px, 1px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 1px) rotate(-1deg); }
  60% { transform: translate(-2px, 1px) rotate(0deg); }
  70% { transform: translate(1px, 1px) rotate(-1deg); }
  70% { transform: translate(1px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 1px) rotate(0deg); }
  100% { transform: translate(1px, -1px) rotate(-1deg); }
}


@keyframes logoA {
  0% {
    opacity: 0;
    left: -1000px;
  }
  90% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
    left : 0;
  }
}

@keyframes iconFadeInA {
  0% {
    fill : #ffffff;
    opacity: 0;
  }
  100% {
    fill : #61dafb;
    opacity : 1;
  }
}

@keyframes opacityOnToOff {
  0% {
    opacity: 1;
  }
  100% {
    opacity : 0;
  }
}

#root {
  height: 100vh;
  min-height: 100%;
}

html *
{
  font-size: 1em !important;
  color: #fff !important;
  font-family: "Source Code Pro" !important;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
